@import 'tailwindcss/components';

@layer components {
  .skeleton {
    @apply h-[26px] w-[33%] rounded-full bg-gray-200;
    @apply tablet:w-[66%];
    @apply sm:w-full;
    @apply xs:w-full;
  }
}
