@import 'tailwindcss/components';

@layer components {
  .skeleton {
    @apply rounded-full bg-gray-200;
  }
  .shipment-items-container.skeleton {
    @apply flex h-fit w-full flex-col rounded-none bg-white;
  }
  .shipment-items-title.skeleton {
    @apply h-[36px] w-[275px];
    @apply sm:w-[80%];
  }
  .shipment-items-carousel.skeleton {
    @apply flex h-full w-full flex-wrap rounded-none bg-white;
    @apply md:flex-nowrap;
  }
  .product-item-container.skeleton {
    @apply flex h-full w-full rounded-none bg-white;
    @apply tablet:w-[50%];
    @apply sm:w-[66%];
  }
  .product-item-image.skeleton {
    @apply flex h-[133px] w-full items-center justify-center rounded bg-gray-300;
    @apply sm:w-[95px];
    @apply xs:w-[85px];
  }
  .product-item-description.skeleton {
    @apply flex h-full w-full rounded-none bg-white;
  }
  .product-item-designer.skeleton {
    @apply flex h-[16px] w-[75%] items-center;
  }
  .product-item-price.skeleton {
    @apply flex h-[18px] w-[40%] items-center;
  }
}
