@import 'tailwindcss/components';

@layer components {
  .typography-body1 {
    @apply text-[13px];
  }
  .typography-font-centra-medium {
    @apply font-centra-medium;
  }
  .typography-font-centra-bold {
    @apply font-centra-bold;
  }
  .typography-font-centra-book {
    @apply font-centra-book;
  }
  .typography-font-ogg {
    @apply font-ogg;
  }
  .typography-font-ogg-bold {
    @apply font-ogg-bold;
  }
  .typography-color-inherit {
    color: inherit;
  }
  .typography-color-shadow {
    @apply text-shadow;
  }
  .typography-color-gray-dark {
    @apply text-gray-dark;
  }
  .typography-align-inherit {
    text-align: inherit;
  }
  .typography-align-left {
    @apply text-left;
  }
  .typography-align-center {
    @apply text-center;
  }
  .typography-align-right {
    @apply text-right;
  }
  .typography-align-justify {
    @apply text-justify;
  }
  .typography-transform-capitalize {
    @apply capitalize;
  }
  .typography-transform-uppercase {
    @apply uppercase;
  }
  .typography-transform-lowercase {
    @apply lowercase;
  }
  .typography-weight-normal {
    @apply font-normal;
  }
  .typography-weight-medium {
    @apply font-medium;
  }
  .typography-weight-bold {
    @apply font-bold;
  }
}
