@import 'tailwindcss/components';

@layer components {
  .header-container {
    @apply flex items-center justify-between;
    @apply lg:mx-5 lg:mb-[18px];
    @apply xl:mx-10;
  }
  .header-links {
    @apply flex items-center gap-[22px];
  }
  .my-account-text {
    @apply align-middle text-sm text-shadow underline;
  }
  a.hlink.my-account-link {
    @apply hover:no-underline;
  }
}
