@import 'tailwindcss/components';

@layer components {
  .delivery-transition {
    @apply relative;
  }
  .delivery-transition-center {
    @apply flex h-screen w-screen items-center justify-center;
  }
  .delivery-missing-modal-container {
    @apply fixed bottom-0 left-0 right-0 top-0 bg-black bg-opacity-60;
  }
  .delivery-missing-modal {
    @apply mx-auto flex h-fit w-[395px] flex-col items-center justify-items-center gap-[27px] bg-white p-[60px];
    @apply sm:mx-[20px] sm:w-[315px] sm:px-[20px] sm:py-[50px];
    @apply xs:w-fit;
  }
  .delivery-missing-modal-title {
    @apply text-[26px] not-italic leading-[34px];
  }
  .delivery-missing-modal-description {
    @apply text-3 not-italic;
  }
  .hlink.delivery-missing-modal-description-link {
    @apply underline;
  }
}
