@import 'tailwindcss/components';

@layer components {
  .product-item-container {
    @apply flex h-full flex-col items-center gap-[9px] p-0;
    @apply sm:m-0 sm:flex-[0_0_30%];
  }
  .product-item-image {
    @apply flex w-[100px] flex-col items-start p-0;
    @apply md:w-20;
    @apply sm:w-[95px];
    @apply xs:w-[85px];
  }
  .product-item-description {
    @apply flex flex-col items-center gap-0.5 p-0;
  }
  .product-item-designer {
    @apply flex items-center break-words text-center text-xs font-normal uppercase not-italic tracking-[1.5px] text-shadow;
    word-break: break-word;
  }
  .product-item-price {
    @apply flex items-center text-center text-xs font-normal not-italic leading-[18px] text-gray-dark;
  }
}
