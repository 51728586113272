@import 'tailwindcss/components';

@layer components {
  .order-wrapper {
    @apply mx-auto max-w-[1260px];
    @apply sm:px-[15px] sm:pb-[63px] sm:pt-5;
    @apply md:px-5 md:pb-[63px];
    @apply lg:px-5 lg:pb-[63px];
    @apply xl:px-10 xl:pb-[63px];
  }
}
