@import 'tailwindcss/components';

@layer components {
  .other-shipments-container {
    @apply flex flex-col items-start border-t border-tin pt-[27px];
  }
  .other-shipments-title {
    @apply mb-[18px] text-5 capitalize tracking-[0.5px] text-shadow;
    @apply sm:mb-[27px];
    @apply tablet:mb-[27px];
  }
  .other-shipments-items {
    @apply w-full;
  }
}
