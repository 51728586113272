@font-face {
  font-family: Centra No1 Bold;
  src: url('src/assets/fonts/centrano1-bold-webfont.woff2') format('woff2'),
    url('src/assets/fonts/centrano1-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Centra No1 Book;
  src: url('src/assets/fonts/centrano1-book-webfont.woff2') format('woff2'),
    url('src/assets/fonts/centrano1-book-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Centra No1 Medium;
  src: url('src/assets/fonts/centrano1-medium-webfont.woff2') format('woff2'),
    url('src/assets/fonts/centrano1-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
