@import 'tailwindcss/components';

@layer components {
  .other-shipments-items-container {
    @apply relative flex grow flex-row overflow-hidden;
    @apply sm:flex-wrap sm:gap-x-[2%] sm:gap-y-[18px];
    @apply tablet:flex-wrap tablet:gap-x-[15px] tablet:gap-y-[18px];
  }
  .other-shipments-product-item-wrapper {
    @apply sm:w-[23%];
  }
  .other-shipments-product-item-container {
    @apply mr-[9px] flex min-w-[74px] max-w-[74px] flex-col items-center gap-[9px] p-0;
    @apply sm:mr-0 sm:min-w-full sm:max-w-none;
  }
  .other-shipments-product-item-container.transparent {
    @apply opacity-50;
    @apply sm:opacity-100;
    @apply tablet:opacity-100;
  }
  .other-shipments-product-item-image {
    @apply flex flex-col items-start p-0;
  }
}
