@import 'tailwindcss/components';

@layer components {
  .hlink {
    @apply inline-block font-centra-book;
  }

  .hlink:not(.hlink-primary, .hlink-secondary, .hlink-accent) {
    @apply text-gray-dark;
  }

  .hlink:not(
      .hlink-primary,
      .hlink-secondary,
      .hlink-cta,
      .hlink-decorate,
      .hlink-accent
    ) {
    @apply no-underline hover:underline;
  }

  /** Primary .hlink Secondary .hlink Accent*/
  .hlink-primary,
  .hlink-accent,
  .hlink-secondary {
    @apply rounded-sm px-6 py-3 text-2 font-medium uppercase tracking-[1.5px] transition-colors;
  }
  .hlink-primary {
    @apply bg-black text-white;
    @apply hover:bg-accent hover:text-white;
  }
  .hlink-secondary {
    @apply bg-white text-black;
    box-shadow: inset 0px 0px 0px 2px #000000;
    @apply hover:bg-gray-dark hover:text-white;
  }
  .hlink-secondary:hover {
    box-shadow: inset 0px 0px 0px 2px #444444;
  }
  .hlink-accent {
    @apply border-2 border-gray-dark bg-white text-black;
    @apply hover:border-accent hover:bg-accent hover:text-white;
  }

  /** Decorate */
  .hlink-decorate,
  .hlink-cta {
    @apply underline hover:no-underline;
  }

  /** Cta */
  .hlink-cta {
    @apply my-auto text-center text-2 font-bold uppercase decoration-2 underline-offset-4;
  }

  /** Icon */
  .hlink-icon,
  .hlink-icon-text .icon {
    @apply leading-[0];
  }
  .hlink-icon,
  .hlink-icon-text {
    @apply bg-transparent;
  }
  .hlink-icon:hover:not(:disabled) svg {
    filter: brightness(0) saturate(100%) invert(28%) sepia(47%) saturate(1972%)
      hue-rotate(151deg) brightness(89%) contrast(101%);
  }
  .hlink-icon-disabled-hover:hover svg {
    filter: none !important;
  }
  /** Icon .hlink Text */
  .hlink-icon-text {
    @apply flex items-center justify-center;
  }
  .hlink-icon-text:not(.hlink-icon-text-reverse) > *:first-child {
    @apply mr-1;
  }
  .hlink-icon-text:is(.hlink-icon-text-reverse) > *:last-child {
    @apply mr-1;
  }
  .hlink-icon-text-reverse {
    @apply flex-row-reverse;
  }
  .hlink-icon-text:hover .hlink-label {
    @apply underline;
  }
}
