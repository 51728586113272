@import 'tailwindcss/components';

@layer components {
  .tracking-dialog-show-text {
    @apply mt-[9px] text-[18px] leading-[26px] underline;
  }
  .tracking-dialog-transition-wrapper {
    @apply w-full;
  }
  .tracking-dialog-content-wrapper {
    @apply max-h-[631px] w-[450px] rounded-none px-[58px] pb-11 pt-[60px];
    @apply tablet:max-h-[686px] tablet:w-full tablet:rounded-t-3xl tablet:px-5 tablet:pb-[34px];
    @apply sm:flex sm:h-[85vh] sm:max-h-none sm:w-full sm:flex-col sm:rounded-t-3xl sm:px-5 sm:pb-[34px];
  }
  .tracking-dialog-close-button {
    @apply right-[18px] top-[18px];
  }
  .tracking-dialog-close-button svg {
    @apply h-5 w-5;
  }
  .tracking-dialog-button {
    @apply w-full;
  }
}
