@import 'tailwindcss/components';

@layer components {
  .other-shipments-track-button {
    @apply flex max-h-[45px] min-w-[200px] flex-row items-center justify-center gap-[9px] rounded-sm border border-black px-5 py-[12.5px] align-middle;
    @apply sm:w-full;
  }
  .other-shipments-track-button.hlink {
    @apply hover:no-underline;
  }
  .other-shipments-track-button-text {
    @apply text-2 uppercase leading-5 tracking-[1.5px] text-black;
  }
  .other-shipments-track-button:hover .other-shipments-track-button-text {
    @apply text-white;
  }
}
