@import 'tailwindcss/components';

@layer components {
  .navigation-bar {
    @apply w-full border-y;
    @apply sm:m-0 sm:h-screen sm:border-none;
    @apply md:m-0 md:border-none;
  }
  .navigation-container {
    @apply mx-auto max-w-[1260px];
  }
  .navigation-item {
    @apply sm:w-full sm:border-b;
    @apply md:w-full md:border-b;
  }
  .navigation-item:last-child {
    @apply sm:border-none;
    @apply md:border-none;
  }
  .navigation-list {
    @apply flex items-center justify-between;
    @apply sm:mx-0 sm:flex-col;
    @apply md:mx-0 md:flex-col;
    @apply lg:mx-5;
    @apply xl:mx-10;
  }
  .navigation-link {
    @apply flex h-full basis-5 items-center border-b-2 border-transparent pb-[6px] pt-2;
    @apply sm:ml-5 sm:border-none sm:py-3;
    @apply md:ml-5 md:py-3;
    @apply hover:no-underline;
  }
  a.hlink.navigation-link {
    @apply hover:no-underline;
  }
  .navigation-link:hover {
    @apply border-b-2 border-petrol-bright;
    @apply sm:border-transparent sm:py-3;
    @apply md:border-transparent md:py-3;
  }
  .navigation-link.red-accent:hover {
    @apply border-b-2 border-red-bright;
    @apply sm:border-transparent sm:py-3;
    @apply md:border-transparent md:py-3;
  }
  .navigation-text {
    @apply mt-[3px] overscroll-y-contain text-[13px] uppercase leading-4 tracking-[1.5px] text-black;
    @apply sm:text-2/[20px];
    @apply md:text-2/[20px];
  }
  .navigation-link.red-accent .navigation-text {
    @apply text-red-bright;
  }
}
