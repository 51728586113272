@import 'tailwindcss/components';

@layer components {
  .product-card {
    @apply relative mx-auto my-5 grid place-content-center;
  }
  .product-card-simple {
    @apply mx-auto grid max-w-screen-xl place-content-center px-4 text-gray-dark no-underline hover:underline;
  }
  .product-brand {
    @apply block font-centra-medium text-1 font-medium uppercase tracking-[1.5px] text-shadow;
  }
  .product-name {
    @apply block text-2 font-normal tracking-wide text-gray-dark;
  }
  .product-badge {
    @apply absolute ml-3 mt-3 bg-yellow px-[6px] py-[2px] text-0 font-medium uppercase tracking-[1.5px];
  }
  .product-price-cut {
    @apply mx-1 block text-1/[18px] font-normal tracking-wide line-through;
  }
  .product-price {
    @apply mx-1 font-centra-medium text-1/[18px] tracking-wide text-shadow;
  }
  .product-price-sale-final {
    @apply block text-0 text-gray-mid;
  }
  .product-discount {
    @apply block text-0 uppercase tracking-[1.5px] text-gray-dark;
    @apply before:content-['('] after:content-[')'];
  }
}
