@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'shared/css/styles.css';

@layer base {
  html {
    @apply font-base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    @apply outline-none;
  }

  body {
    @apply m-0;
  }
}
