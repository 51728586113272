@import 'tailwindcss/components';

@layer components {
  .tracking-dialog-carrier-wrapper {
    @apply border-b border-tin pb-[18px] pt-[27px];
  }
  .tracking-dialog-events-wrapper {
    @apply h-[344px] overflow-auto scrollbar-hide;
    @apply sm:h-auto sm:grow;
  }
  .tracking-dialog-footer-wrapper {
    @apply border-t border-shadow pt-2;
  }
}
