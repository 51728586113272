@import 'tailwindcss/components';

@layer components {
  .dialog-attributes {
    @apply fixed left-0 top-0 z-40 m-0 h-full w-screen;
  }
  .dialog-attributes-center {
    @apply grid content-center;
  }
  /** Overlay */
  .dialog-overlay {
    @apply fixed left-0 top-0 h-screen w-screen bg-black bg-opacity-50;
  }

  .dialog-panel {
    @apply bg-white;
  }

  .dialog-panel-left {
    @apply relative inline-block h-screen max-w-[90vw];
  }

  .dialog-panel-right {
    @apply relative float-right inline-block h-screen max-w-[90vw];
  }

  .dialog-panel-top {
    @apply relative max-h-[90%] w-screen;
  }

  .dialog-panel-center {
    @apply relative m-auto grid;
  }

  .dialog-transition {
    @apply relative;
  }

  .dialog-transition-left {
    @apply inline-block h-screen;
  }

  .dialog-transition-right {
    @apply float-right inline-block h-screen;
  }

  .dialog-transition-top {
    @apply block max-h-screen overflow-auto;
  }

  .dialog-transition-center {
    @apply mx-auto inline-block h-fit;
  }

  .dialog-transition-bottom {
    @apply absolute bottom-0 left-2/4 translate-x-[-50%];
  }
}
