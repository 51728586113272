@import 'tailwindcss/components';

@layer components {
  .tracking-history-carrier {
    @apply flex items-center;
  }
  .carrier-logo-wrapper {
    @apply mr-[9px] flex w-[60px] shrink-0 items-center justify-center;
  }
  .carrier-info {
    @apply text-3;
    word-break: break-word;
  }
  .carrier-info .carrier-tracking-number {
    @apply text-black underline;
  }
}
