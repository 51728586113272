@import 'tailwindcss/components';

@layer components {
    .btn {
        @apply p-0 text-inherit;
    }
    .btn:not([disabled]) {
        @apply cursor-pointer;
    }

    /** Primary & Secondary */
    .btn-primary,
    .btn-secondary {
        @apply rounded-sm border-none font-medium uppercase tracking-[1.5px] transition-colors;
    }

    .btn-primary:not(.btn-sm),
    .btn-secondary:not(.btn-sm) {
        @apply px-6 py-3 text-2;
    }

    .btn-primary.btn-sm,
    .btn-secondary.btn-sm {
        @apply py-1.5 px-5 text-1;
    }

    /** Close */
    .btn-close {
        @apply opacity-75 transition-opacity;
    }

    .btn-close:hover {
        @apply opacity-100;
    }

    /** Icon & Close */
    .btn-icon,
    .btn-icon-text .icon,
    .btn-close {
        @apply leading-[0];
    }
    .btn-icon,
    .btn-icon-text,
    .btn-close {
        @apply p-0 m-0 bg-transparent border-0;
    }

    /** Icon & Text */
    .btn-icon-text {
        @apply flex justify-center items-center;
    }

    .btn-icon-text:not(.btn-icon-text-reverse) > *:first-child {
        @apply mr-1;
    }
    .btn-icon-text:is(.btn-icon-text-reverse) > *:last-child {
        @apply mr-1;
    }
    .btn-icon-text-reverse {
        @apply flex-row-reverse;
    }
    .btn-icon-text:hover:not([disabled]) .btn-label {
        @apply underline;
    }

    /** Text */
    .btn-text {
        @apply tracking-normal
    }

    .btn-text:hover:not([disabled]) .btn-label {
        @apply underline;
    }

    /** Disabled */
    .btn-primary:not([disabled]) {
        @apply bg-black text-white;
        @apply hover:bg-accent hover:text-white;
    }
    .btn-secondary:not([disabled]) {
        @apply bg-white text-black;
        box-shadow: inset 0px 0px 0px 2px #000000;
        @apply hover:bg-gray-dark hover:text-white;
    }
    .btn-secondary:not([disabled]):hover {
        box-shadow: inset 0px 0px 0px 2px #444444;
    }

    .btn-primary[disabled],
    .btn-secondary[disabled] {
        @apply disabled:bg-tin disabled:text-gray-mid;
    }
    .btn-icon:is([disabled]) {
        @apply opacity-50;
    }

    .btn-close:is([disabled]) {
        @apply opacity-50;
    }
    .btn-icon-text[disabled] .icon,
    .btn-icon-text[disabled] .btn-label,
    .btn-text[disabled] .btn-label {
        @apply opacity-50;
    }

    /** Block */
    .btn-primary:not(.btn-block),
    .btn-secondary:not(.btn-block) {
        @apply relative;
    }
    .btn-block {
        @apply block w-full;
    }
}
