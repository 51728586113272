@import 'tailwindcss/components';

@layer components {
  .tracking-widget-date-container {
    @apply flex grow-0 flex-col items-start gap-1 p-0;
    @apply sm:gap-[3px];
  }
  .tracking-widget-date-shippment-status {
    @apply text-3/[22px] font-normal uppercase not-italic tracking-[1.5px] text-petrol-dark-1;
  }
  .tracking-widget-date-delivery-text {
    @apply text-[34px]/[44px] font-bold not-italic tracking-[0.5px] text-petrol-dark-3;
    @apply sm:text-[26px]/[36px];
  }
}
