@import 'tailwindcss/components';

@layer components {
  .shipment-container {
    @apply flex flex-row items-center justify-between gap-[27px] p-0;
    @apply sm:flex-col sm:items-start sm:justify-start;
    @apply tablet:flex-col tablet:items-start tablet:justify-start;
  }
  .shipment-container:not(:last-child) {
    @apply mb-[18px] border-b pb-[18px];
    @apply sm:mb-[27px] sm:pb-[27px];
    @apply tablet:mb-[27px] tablet:pb-[27px];
  }
}
