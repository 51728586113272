@import 'tailwindcss/components';

@layer components {
  .tracking-widget-container {
    @apply flex max-h-fit flex-col items-start overflow-hidden bg-petrol-light-1 px-[20px] py-[30px];
    @apply sm:px-[15px] sm:py-[20px];
  }
  .stepper-wrapper {
    @apply -ml-10 w-[517px];
    @apply md:-ml-[18px] md:w-[350px];
    @apply tablet:-ml-[10%] tablet:w-[115%];
    @apply sm:-ml-[5%] sm:w-[110%];
    @apply xs:-ml-[3%] xs:w-[105%];
  }
  a.hlink.need-help-link {
    @apply mt-[27px] text-lg font-normal not-italic leading-4 text-shadow underline;
  }
}
