@import 'tailwindcss/components';

@layer components {
  .shipment-items-container {
    @apply box-border flex w-full flex-col items-start gap-[18px] rounded-sm border border-solid border-gray-light p-[18px];
    @apply tablet:static;
    @apply sm:static sm:p-[15px];
    @apply xs:p-3;
  }
  .shipment-items-title {
    @apply text-[26px] font-normal capitalize not-italic leading-9 tracking-[0.5px] text-shadow;
  }
}
