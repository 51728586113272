@import 'tailwindcss/components';

@layer components {
  .einstein-widget-carousel {
    @apply flex w-full items-start justify-center gap-[9px] pt-[20px];
    @apply lg:p-0;
    @apply md:px-0;
    @apply sm:px-0;
  }
  .einstein-widget-carousel .carousel-container {
    @apply md:mx-0 md:w-full;
    @apply sm:mx-0 sm:w-full;
  }
  .einstein-widget-carousel .product-card .hlink > div {
    @apply sm:px-[15px];
    @apply md:px-[15px];
  }
}
