@import 'tailwindcss/components';

@layer components {
  .other-shipments-summary-container {
    @apply flex min-w-[171px] flex-col;
  }
  .other-shipments-summary {
    @apply text-3 text-black;
  }
  .other-shipments-date {
    @apply text-3 text-black;
  }
}
