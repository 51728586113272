@import 'tailwindcss/components';

@layer components {
  .stage-number {
    @apply mt-[5px] text-center text-[12px] font-normal text-petrol-dark-2;
  }
  .bubble-container {
    @apply flex h-7 w-7 items-center justify-center rounded-full border border-petrol-dark-2 bg-petrol-light-1;
  }
}
