@import 'tailwindcss/components';

@layer components {
  .shipment-summary-text {
    @apply text-[18px] font-normal leading-[26px] text-petrol-dark-1;
    @apply lg:text-[26px] lg:leading-[34px];
  }
  .shipment-summary {
    @apply mt-[18px];
    @apply shipment-summary-text;
  }
  .hlink.shipment-summary-link {
    @apply shipment-summary-text;
    @apply underline;
  }
}
