@import 'tailwindcss/components';

@layer components {
  .carousel-container {
    @apply mx-auto mb-7 w-[90%];
  }
  .custom-slick-dots {
    @apply absolute flex w-full justify-center text-center !important;
  }
  .custom-slick-dots li {
    @apply mx-4 mt-3 h-[7px] w-[7px] rounded-full border border-shadow;
  }
  .custom-slick-dots li.slick-active {
    @apply bg-gray-mid;
  }
  .custom-slick-dots li button {
    @apply text-transparent;
  }
  button.custom-arrow {
    @apply h-auto w-auto text-3xl text-shadow before:content-none;
  }
  button.custom-arrow svg path {
    @apply fill-shadow;
  }
  button.custom-arrow svg {
    @apply h-7 w-7;
  }
  button.custom-arrow.slick-disabled {
    @apply cursor-default text-steel;
  }
  button.custom-arrow.slick-disabled svg path {
    @apply fill-steel;
  }
  .custom-arrow:focus {
    @apply text-shadow;
  }
  .custom-arrow:hover {
    @apply text-shadow;
  }
  .custom-arrow:focus svg path {
    @apply fill-shadow;
  }
  .custom-arrow:hover svg path {
    @apply fill-shadow;
  }
}
