@import 'tailwindcss/components';

@layer components {
  .stepper-container {
    @apply mt-[27px] flex w-full flex-row items-start justify-around gap-0 p-0;
    @apply sm:mt-[18px];
  }
  .icon-container {
    @apply relative flex flex-1 flex-col items-center justify-items-center gap-[9px] p-0;
  }
  .icon-container > * {
    @apply z-[5];
  }
  .icon-container:not(:first-child)::before {
    @apply absolute -left-[48%] top-[13px] z-[2] w-[95%] border-b-4 border-petrol-light-2 content-[''];
  }
  .icon-container.active::before {
    @apply z-[3] border-b-4 border-petrol-light-4;
  }
  .stage-description {
    @apply items-start text-center text-[13px] not-italic leading-[18px] text-petrol-dark-2;
    @apply lg:text-[14px] lg:leading-[20px];
  }
}
