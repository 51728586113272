@import 'tailwindcss/components';

@layer components {
  .skeleton {
    @apply rounded-full bg-gray-200;
  }
  .other-shipments-title.skeleton {
    @apply h-[36px] w-[320px];
    @apply sm:w-full;
  }
  .other-shipments-summary.skeleton {
    @apply mb-2 h-[16px] w-full;
    @apply sm:w-[75%];
  }
  .other-shipments-date.skeleton {
    @apply mb-2 h-[20px] w-[75%];
    @apply sm:w-[50%];
  }
  .other-shipments-product-item-image.skeleton {
    @apply flex h-[98px] w-16 items-center justify-center rounded bg-gray-300;
  }
  .other-shipments-track-button.skeleton {
    @apply h-[45px] w-[200px] rounded border-none;
    @apply sm:w-full;
  }
}
