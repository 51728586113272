@import 'tailwindcss/components';

@layer components {
  .tracking-wrapper {
    @apply w-[480px];
    @apply md:w-[355px];
    @apply tablet:w-full;
    @apply sm:w-full;
  }
  .shipment-wrapper {
    @apply relative mb-[63px] flex h-fit items-start justify-start gap-3;
    @apply sm:mb-[54px] sm:h-auto sm:flex-col sm:gap-[36px];
    @apply tablet:mb-[54px] tablet:h-auto tablet:flex-col tablet:gap-[36px];
    @apply lg:mb-[54px] lg:gap-[27px];
  }
  .shipment-items-wrapper {
    @apply h-full w-[calc(100%-367px)];
    @apply sm:h-auto sm:w-full;
    @apply tablet:h-auto tablet:w-full;
    @apply lg:w-[calc(100%-507px)];
  }
  .shipment-items-wrapper .slick-track {
    @apply ml-0;
  }
}
