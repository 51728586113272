@font-face {
  font-family: Ogg Bold;
  src: url('src/assets/fonts/ogg-bold-webfont.woff2') format('woff2'),
    url('src/assets/fonts/ogg-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Ogg Medium;
  src: url('src/assets/fonts/ogg-medium-webfont.woff2') format('woff2'),
    url('src/assets/fonts/ogg-medium-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
