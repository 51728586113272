@import 'tailwindcss/components';

@layer components {
  .hamburger-button {
    @apply flex h-5 w-6 flex-col items-center justify-center;
  }
  .hamburger-line {
    @apply my-[2.75px] h-[3px] w-6 transform bg-shadow transition duration-300 ease-linear;
  }
  .hamburger-line:first-child {
    @apply mt-0;
  }
  .hamburger-line:last-child {
    @apply mb-0;
  }
  .hamburger-line.top-line.opened {
    @apply h-[2px] translate-y-[8px] rotate-45;
  }
  .hamburger-line.middle-line.opened {
    @apply opacity-0;
  }
  .hamburger-line.bottom-line.opened {
    @apply h-[2px] -translate-y-[8px] -rotate-45;
  }
}
