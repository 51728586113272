@import 'tailwindcss/components';
@import './_hamburger-button.css';
@import './_header-desktop.css';
@import './_header-mobile.css';

@layer components {
  header {
    @apply relative z-10 mx-auto mb-9 w-full bg-white transition-all;
    @apply sm:mb-[18px] sm:border-b sm:border-tin sm:pb-[22px] sm:pt-[18px];
    @apply md:border-b md:border-tin md:py-5;
    @apply lg:mt-5;
    @apply xl:mt-[30px];
  }
  body:has(.dialog-menu-container) header {
    @apply relative w-screen transition-all;
  }
  .header-wrapper {
    @apply mx-auto max-w-[1260px];
  }
  .profile-icon {
    @apply align-middle text-shadow;
  }
  .wishlist-icon {
    @apply align-middle text-shadow;
  }
  .logo-icon {
    @apply align-middle;
  }
}
