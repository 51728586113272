@import 'tailwindcss/components';
@import './_einstein-widget-carousel.css';

@layer components {
  .einstein-widget-container {
    @apply mb-[63px] flex flex-col items-center justify-center gap-[27px] border-t border-tin p-0 pt-[27px] last:mb-0;
    @apply sm:mb-[100px];
  }
  .einstein-widget-container .product-card {
    @apply max-w-[220px];
    @apply sm:max-w-[151px];
  }
  .einstein-widget-title {
    @apply flex items-center text-[26px] font-normal capitalize not-italic leading-9 tracking-[0.5px] text-shadow;
  }
}
