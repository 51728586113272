@import 'tailwindcss/components';

@layer components {
  .check-mark-surface {
    @apply stroke-white stroke-[1.5px];
  }
  .check-mark-svg {
    @apply h-7 w-7;
  }
}
