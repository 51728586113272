@import 'tailwindcss/components';

@layer components {
  .header-container-mobile {
    @apply mx-4 flex items-center justify-between;
    @apply md:mx-[21px];
  }
  .header-container-mobile > * {
    @apply flex-1;
  }
  .hamburger-container {
    @apply flex items-center justify-start;
  }
  .header-links-mobile {
    @apply flex items-center justify-end gap-[21px];
  }
  .logo-container {
    @apply flex items-center justify-center;
  }
  .dialog-menu-container {
    @apply mt-[76px] min-w-[315px] rounded-b-3xl;
    @apply md:mt-[82px];
  }
  .dialog-attributes:has(> .dialog-transition .dialog-menu-container) {
    @apply z-[5];
  }
  .header-container-mobile .logo-icon svg {
    @apply h-[35px] w-[71px];
    @apply md:h-[41px] md:w-[83px];
  }
  .navigation-bar-container {
    @apply max-h-[85vh] overflow-auto scrollbar-hide;
  }
}
