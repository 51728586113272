@import 'tailwindcss/components';

@layer components {
  .shipment-items-carousel {
    @apply flex w-full flex-row items-start gap-[15px];
    @apply sm:flex-wrap sm:gap-x-[0];
    @apply xs:gap-y-[10px];
  }
  .shipment-items-carousel > .product-item-container {
    @apply w-[100px];
    @apply tablet:w-[80px];
    @apply sm:basis-[32%] sm:overflow-hidden;
  }
  .shipment-items-carousel > .product-item-container:nth-child(3n + 2) {
    @apply sm:mx-[2%];
  }
  .shipment-items-carousel > .product-item-container .product-item-image {
    @apply sm:w-full;
  }
  .shipment-items-carousel > .product-item-container .product-item-description {
    @apply xs:break-all;
  }
  .shipment-items-carousel .carousel-container:has(button.slick-arrow) {
    @apply mx-[25px] w-[calc(100%-50px)];
  }
  .shipment-items-carousel .slick-slide > div {
    @apply px-[7.5px];
  }
}
