@import 'tailwindcss/components';

@layer components {
  .order-number-text {
    @apply text-lg/[26px] font-normal text-black;
  }
  .order-number-container {
    @apply mb-[27px] items-center first-line:flex;
    @apply sm:mb-[18px];
  }
  .order-number-link {
    @apply underline;
  }
}
