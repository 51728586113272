@import 'tailwindcss/components';

@layer components {
  .skeleton {
    @apply rounded-full bg-gray-200;
    @apply tablet:w-[66%];
    @apply sm:w-full;
    @apply xs:w-full;
  }
  .tracking-widget-container.skeleton {
    @apply h-full w-full rounded-none bg-white;
  }
  .tracking-widget-date-container {
    @apply w-full;
  }
  .icon-container.skeleton-link:not(:first-child)::before {
    @apply border-gray-200;
  }
  .tracking-widget-date-shippment-status.skeleton {
    @apply h-[22px] w-[40%];
    @apply tablet:w-[30%];
  }
  .tracking-widget-date-delivery-text.skeleton {
    @apply h-[44px] w-[75%];
    @apply tablet:w-[50%];
  }
  .shipment-summary.skeleton {
    @apply h-[26px] w-[90%];
  }
  .bubble-container.skeleton {
    @apply border-gray-200 bg-gray-200;
  }
}
