@import 'tailwindcss/components';

@layer components {
  .footer-wrapper {
    @apply bg-oxygen px-5 py-[18px] text-2 text-gray-dark;
    @apply sm:px-[15px];
    @apply md:px-5;
    @apply lg:px-5;
    @apply xl:px-10;
  }
  .footer-wrapper .footer-container {
    @apply md:max-w-full;
    @apply sm:max-w-full;
  }
  .footer-social {
    @apply py-[18px] text-center;
    @apply lg:flex lg:text-left;
    @apply md:pt-[33px];
    @apply sm:pt-[33px];
  }
  .footer-social-link {
    @apply mx-[17.5px];
  }
  .footer-social-link:first-child {
    @apply ml-[0px];
  }
  .footer-social-link:last-child {
    @apply mr-[0px];
  }
  .footer-app-wrapper {
    @apply flex items-center justify-center text-center;
    @apply lg:float-right;
    @apply md:mb-[18px];
    @apply sm:mb-[18px];
  }
}
