@import 'tailwindcss/components';

@layer components {
  footer {
    @apply mt-[100px]
  }
  .order-item-container {
    @apply flex flex-row items-start gap-[27px] p-0;
  }
}
